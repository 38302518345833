import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from 'react-i18next';

const Split = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="main-content">
                {/* First */}
                <div className="rwt-split-area rn-section-gap">
                    <div className="wrapper">
                        <div className="rn-splite-style bg-color-blackest">
                            <div className="split-wrapper">
                                <div className="row no-gutters radius-10 align-items-center">
                                    <div className="col-lg-12 col-xl-6 col-12">
                                        <div className="thumbnail">
                                            <img src="./images/managerPanel.png" alt="split Images" />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-xl-6 col-12">
                                        <div className="split-inner">
                                            <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                                                <h4 className="title">{t('ManagerPanel')}</h4>
                                            </ScrollAnimation>
                                            <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                                                <p className="description">{t('ManagerPanelDesc')}</p>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Second */}
                <div className="rn-splite-style bg-color-blackest">
                    <div className="split-wrapper">
                        <div className="row no-gutters radius-10 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-12 col-xl-6 col-12">
                                <div className="split-inner">
                                    <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                                        <h4 className="title">{t('OwnerPanel')}</h4>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                                        <p className="description">{t('OwnerPanelDesc')}</p>
                                    </ScrollAnimation>
                                </div>
                            </div>
                            <div className="order-1 order-lg-2 col-lg-12 col-xl-6 col-12">
                                <div className="thumbnail">
                                    <img src="./images/ownerPanel.png" alt="split Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Third */}
                <div className="rwt-split-area rn-section-gap">
                    <div className="wrapper">
                        <div className="rn-splite-style bg-color-blackest">
                            <div className="split-wrapper">
                                <div className="row no-gutters radius-10 align-items-center">
                                    <div className="col-lg-12 col-xl-6 col-12">
                                        <div className="thumbnail">
                                            <img src="./images/guestPanel.png" alt="split Images" />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-xl-6 col-12">
                                        <div className="split-inner">
                                            <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                                                <h4 className="title">{t('GuestPanel')}</h4>
                                            </ScrollAnimation>
                                            <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                                                <p className="description">{t('GuestPanelDesc')}</p>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Split;
