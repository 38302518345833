import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from 'react-i18next';

const TimelineOne = () => {
    const { t } = useTranslation();

    return (
        <div className="rn-timeline-wrapper timeline-style-one position-relative">
            <div className="timeline-line"></div>

            {/* Start Single Timeline 1 */}
            <div className="single-timeline mt--50">
                <div className="timeline-dot">
                    <div className="time-line-circle"></div>
                </div>
                <div className="single-content">
                    <div className="inner">
                        <div className="row row--30 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                <div className="content">
                                    <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                                        <span className="date-of-timeline">{t('SmartTechnologyDate')}</span>
                                    </ScrollAnimation>

                                    <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                                        <h2 className="title">{t('SmartTechnologyTitle')}</h2>
                                    </ScrollAnimation>

                                    <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                                        <p className="description">{t('SmartTechnologyDesc')}</p>
                                    </ScrollAnimation>

                                    <div className="read-morebtn">
                                        <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                                            <a className="btn-default btn-large round" href="/about#sumtech-smart" rel="noopener noreferrer">
                                                <span>{t('LearnMore')}</span>
                                            </a>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                            </div>
                            <div className="order-1 order-lg-2 col-lg-6">
                                <div className="thumbnail">
                                    <img className="w-100" src={`./images/timeline/smart.png`} alt="Corporate React Template" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Start Single Timeline 2 */}
            <div className="single-timeline mt--50">
                <div className="timeline-dot">
                    <div className="time-line-circle"></div>
                </div>
                <div className="single-content">
                    <div className="inner">
                        <div className="row row--30 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                <div className="content">
                                    <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                                        <span className="date-of-timeline">{t('SmartHospitalityDate')}</span>
                                    </ScrollAnimation>

                                    <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                                        <h2 className="title">{t('SmartHospitalityTitle')}</h2>
                                    </ScrollAnimation>

                                    <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                                        <p className="description">{t('SmartHospitalityDesc')}</p>
                                    </ScrollAnimation>

                                    <div className="read-morebtn">
                                        <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                                            <a className="btn-default btn-large round" href="/about#smart-apt" rel="noopener noreferrer">
                                                <span>{t('LearnMore')}</span>
                                            </a>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                            </div>
                            <div className="order-1 order-lg-2 col-lg-6">
                                <div className="thumbnail">
                                    <img className="w-100" src={`./images/timeline/airbnb.png`} alt="Corporate React Template" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Start Single Timeline 3 */}
            <div className="single-timeline mt--50">
                <div className="timeline-dot">
                    <div className="time-line-circle"></div>
                </div>
                <div className="single-content">
                    <div className="inner">
                        <div className="row row--30 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                <div className="content">
                                    <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                                        <span className="date-of-timeline">{t('AllInOnePlatformDate')}</span>
                                    </ScrollAnimation>

                                    <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                                        <h2 className="title">{t('AllInOnePlatformTitle')}</h2>
                                    </ScrollAnimation>

                                    <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                                        <p className="description">{t('AllInOnePlatformDesc')}</p>
                                    </ScrollAnimation>

                                    <div className="read-morebtn">
                                        <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                                            <a className="btn-default btn-large round" href="/propertyowner" rel="noopener noreferrer">
                                                <span>{t('LearnMore')}</span>
                                            </a>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                            </div>
                            <div className="order-1 order-lg-2 col-lg-6">
                                <div className="thumbnail">
                                    <img className="w-100" src={`./images/timeline/panel.png`} alt="Corporate React Template" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TimelineOne;
