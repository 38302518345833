import React from 'react';

const BrandList = [
    {
        image: './images/brand/7.svg'
    },
    {
        image: './images/brand/8.png'
    },
]

const BrandOne = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <a ><img src={`${data.image}`} alt="Brand Image" /></a>
                </li>
            ))}
        </ul>
    )
}

export default BrandOne;
