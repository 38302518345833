import styled from 'styled-components';
import defaultTheme from '../../theme';

const OptionElement = styled.button`
border: 0;
margin: 0px 10px 2px 10px;
width: 95%;
border-radius: 13px;
transition: 0.5s;
background-size: 100% auto;
color: ${({ theme }) => theme.botFontColor};
display: inline-block;
font-size: 12px;
padding: 12px;
background:#ABAFB1;
color: white;
border-radius: 18px;
padding: 14px 24px 16px;
font-weight: 400;
line-height: 13px;
transition: transform 200ms,background 200ms;
  &:hover {
    transform: translateY(-3px);
    background:linear-gradient(292deg, rgba(14,129,144,1) 10%, rgba(6,117,131,1) 17%, rgba(3,107,121,1) 31%, rgba(0,74,84,1) 82%, rgba(0,55,63,1) 91%);
  }
  &:active,
  &:hover:focus {
    outline:none;
  }
`;


OptionElement.defaultProps = {
  theme: defaultTheme
};

export default OptionElement;
