import styled from 'styled-components';
import { scale } from '../../common/animation';
import defaultTheme from '../../theme';

const Bubble = styled.div`
  animation: ${scale} 0.3s ease forwards;
  background: ${props => (props.user ? props.theme.userBubbleColor='#FDFDFD' : props.theme.botBubbleColor=' linear-gradient(292deg, rgba(14,129,144,1) 0%, rgba(6,117,131,1) 7%, rgba(3,107,121,1) 17%, rgba(0,74,84,1) 62%, rgba(0,55,63,1) 91%)')};
  color:${props => (props.user ? '#000000' : '#F8F8F8')};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  display: inline-block;
  font-size: 12px;
  max-width: 75%;
  min-width: ${props => (props.user ? '' : '75%')};
  overflow: hidden;
  position: relative;
  padding: 10px;
  transform: scale(0);
  
  border-radius: ${props => {
    const { isFirst, isLast, user } = props;

    if (!isFirst && !isLast) {
      return user ? '18px 0 0 18px' : '0 18px 18px 0px';
    }

    if (!isFirst && isLast) {
      return user ? '18px 0 18px 18px' : '0 18px 18px 18px';
    }

    return props.user ? '18px 18px 0 18px' : '18px 18px 18px 0';
  }};
  margin: ${props => {
    const { isFirst, showAvatar, user } = props;
    if (!isFirst && showAvatar) {
      return user ? '-6px 40px 10px 0' : '-8px 0 10px 40px';
    }
    if (!isFirst && !showAvatar) {
      return user ? '-8px 0px 10px 0' : '-8px 0 10px 0px';
    }
    return '0 0 10px 0';
  }};
  transform-origin: ${props => {
    const { isFirst, user } = props;

    if (isFirst) {
      return user ? 'bottom right' : 'bottom left';
    }

    return user ? 'top right' : 'top left';
  }};
`;

Bubble.defaultProps = {
  theme: defaultTheme
};

export default Bubble;
