import React from "react";
import SEO from "../common/SEO";
import TimelineOne from "../elements/timeline/TimelineOne";
import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import BrandOne from "../elements/brand/BrandOne";
import Slider from "react-slick";
import { BannerActivation } from "../utils/script";
import Separator from "../elements/separator/Separator";
import CalltoActionTwo from "../elements/calltoaction/CalltoActionTwo";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import ServiceOne from '../elements/service/ServiceThree';
import { useTranslation } from 'react-i18next';
import Chat from '../common/chat_query';


const TravelAgency = () => {
    const { t } = useTranslation();
    const BannerData = [
        {
            image: "/images/bg/slider.png",
            title: t('BannerTitle1'),
            description: t('BannerDesc1')
        },
        {
            image: "/images/bg/slider2.png",
            title: t('BannerTitle2'),
            description: t('BannerDesc2')
        },
        {
            image: "/images/bg/slider3.png",
            title: t('BannerTitle3'),
            description: t('BannerDesc3')
        }
    ]
    
    const scrollToSection = () => {
        const section = document.getElementById("target-section");
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
            
            window.history.pushState(null, null, ' ');
        }
    };
    return (
        <>
            <Chat/>
            <SEO title="Special Hospitality" />
            <main className="page-wrapper">
            <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />
            {/* Start Slider Area  */}
                <Slider className="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow" {...BannerActivation}>
                    {BannerData.map((data, index) => (
                        <div key={index} className="single-slide">
                            <div className="height-950 bg_image" data-black-overlay="6" style={{backgroundImage: `url(${process.env.PUBLIC_URL} ${data.image})`}}>
                                <div className="container">
                                    <div className="row row--30 align-items-center">
                                        <div className="col-12">
                                            <div className="inner text-center">
                                                <h className="title" dangerouslySetInnerHTML={{__html: data.title}}></h>
                                                <p className="description" dangerouslySetInnerHTML={{__html: data.description}}></p>
                                                <div className="button-group mt--30">
                                                    <button className="btn-default" onClick={() => scrollToSection()}>{t('ViewMore')}</button>
                                                </div>  
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
                {/* End Slider Area  */}

                <div className="rn-service-area rn-section-gap ">
                        <div className="container">
                            <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle={t('WhatWeCanDo')}
                                    title={t('WhyRightChoice')}
                                />
                            </div>
                            </div>
                            <ServiceOne 
                                serviceStyle = "service__style--2"
                                textAlign = "text-center"
                             />
                        </div>
                    </div>
                    {/* End Service Area  */}
                <Separator />
                {/* Start Timeline Area  */}
                <div id="target-section" className="rwt-timeline-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-12">
                            <SectionTitle
                                textAlign="text-center"
                                radiusRounded=""
                                subtitle={t('Solutions')}
                                title={t('OurServices')}
                                description={t('OurServicesDesc')}
                            />
                        </div>
                        </div>
                        <div className="row mt_dec--20">
                            <div className="col-lg-12">
                                <TimelineOne />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Timeline Area  */}
                <Separator />
                {/* Start Elements Area  */}
                <div className="rwt-callto-action-area rn-section-gapBottom">
                    <div className="wrapper">
                        <div className="row">
                            <div className="col-lg-12 mb--40">
                            </div>
                        </div>
                        <CalltoActionTwo />
                    </div>
                </div>
                {/* End Elements Area  */}
                <Separator />
                    {/* Start Brand Area  */}
                    <div className="rwt-brand-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle={t('AwesomeClient')}
                                    title={t('LeadingBrands')}
                                    description=""
                                />
                            </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mt--40">
                                    <BrandOne brandStyle="brand-style-1" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Brand Area  */}
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}
export default TravelAgency;
