import React from 'react';
import ChatBot from '../chatIndex';
import Step from "../data/chatDatas";

const steps = Step

const Chat = () => (
      <ChatBot steps={steps}  
      floating={true}
   
/>
);

export default Chat;
