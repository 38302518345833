export default {
    fontFamily: 'Jost',
   
    headerBgColor: '#F3F3F3',
    headerFontColor: '#222222',
    headerFontSize: '20px',
    
    botBubbleColor: 'linear-gradient(90deg, hsla(203, 33%, 15%, 1) 0%, hsla(191, 28%, 23%, 1) 53%, hsla(207, 22%, 29%, 1) 100%)',
    botFontColor: '#fff',
   
    userBubbleColor: '#fff',
    userFontColor: '#4a4a4a'
  };
  