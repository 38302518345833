import React, { useState, useEffect } from 'react';

const Step = [
    {
    id: 'welcome',
    message: 'Welcome to Summit Vista.',
    trigger: '1',
    },
    {
      id: '1',
      message: 'In which area can i help you?',
      trigger: '2',
    },
    {
      id: '2',
      options: [
        { value: 'Property', label: 'Property Management', trigger: 'Property' },
        { value: 'Technical', label: 'Technical Support', trigger: 'Technical' },
        { value: 'Account', label: 'Account and Settings', trigger: 'Account' },
        { value: 'Contac', label: 'Contact us', trigger: 'Contac' }
      ], 
    },
    //Property start
    {
      id: 'Property',
      options: [
        { value: 'Property1', label: '“How do I access the Owner Panel?”', trigger: 'Property1' },
        { value: 'Property2', label: '“What features are available in the Owner Panel?”', trigger: 'Property2' },
        { value: 'Property3', label: '“ Can I manage multiple properties through the Vista Manager app?”', trigger: 'Property3' },
        { value: 'Property4', label: '“What support is available for property owners?”', trigger: 'Property4' },
        { value: 'talking', label: 'Start talking again', trigger: '1' },
        { value: 'Contact', label: 'Contact us for more details...', trigger: 'Contac' }

      ],
    },
    {
      id: 'Property1',
      message: 'The Owner Panel can be accessed through the Summit Vista app or website, where you can log in using your credentials.',
      trigger :'PropertyEnd',
    },
    {
      id: 'Property2',
      message: 'The Owner Panel offers revenue tracking, expense management,properties overview, market analysis, and compliance reporting.',
      trigger : 'PropertyEnd',
    },
    {
      id: 'Property3',
      message: ' Yes, the app allows you to manage multiple properties easily,providing centralized control and analytics for each.',
      trigger : 'PropertyEnd',
    },
    {
      id: 'Property4',
      message: 'We provide 24/7 support for property owners, including technical assistance and operational guidance.',
      trigger : 'PropertyEnd',
    }, 
    {
      id: 'PropertyEnd',
      options: [
        { value: 'Property', label: 'More questions...', trigger: 'Property' },
        { value: 'talking', label: 'Start talking again', trigger: '1' },
        { value: 'Contac', label: 'Please contact us for detailed information.', trigger: 'Contac' }

      ],
    },
   //Property end

   //Technical start
   {
    id: 'Technical',
    options: [
      { value: 'Technical1', label: '“What should I do if I forget my password?”', trigger: 'Technical1' },
      { value: 'Technical2', label: '“How do I report a problem with the app?”', trigger: 'Technical2' },
      { value: 'Technical3', label: '“How secure is my data with Summit Vista?”', trigger: 'Technical3' },
      { value: 'Technical4', label: '“What devices are compatible with the Summit Vista app?”', trigger: 'Technical4' },
      { value: 'talking', label: 'Start talking again', trigger: '1' },
      { value: 'Contact', label: 'Contact us for more details...', trigger: 'Contac' }
    ],
  },
  {
    id: 'Technical1',
    message: 'Use the \'Forgot Password?\' link on the login page to reset your password, or contact support for further assistance.',
    trigger :'TechnicalEnd',
  },
  {
    id: 'Technical2',
    message: ' Problems can be reported directly through the app\'s \'Help\' section or by contacting our technical support team.',
    trigger : 'TechnicalEnd',
  },
  {
    id: 'Technical3',
    message: 'Your data is protected with advanced encryption and security measures to ensure privacy and safety.',
    trigger : 'TechnicalEnd',
  },
  {
    id: 'Technical4',
    message: 'The app is compatible with most iOS and Android devices.',
    trigger : 'TechnicalEnd',
  }, 
  {
    id: 'TechnicalEnd',
    options: [
      { value: 'Technical', label: 'More questions...', trigger: 'Technical' },
      { value: 'talking', label: 'Start talking again', trigger: '1' },
      { value: 'Contac', label: 'Please contact us for detailed information.', trigger: 'Contac' }

    ],
  },
 //Technical end

    //Account start
    {
      id: 'Account',
      options: [
        { value: 'Account1', label: '“How do I update my account information?”', trigger: 'Account1' },
        { value: 'Account2', label: 'What notifications will I receive from Summit Vista?”', trigger: 'Account2' },
        { value: 'Account3', label: '“How can I opt out of marketing emails?”', trigger: 'Account3' },
        { value: 'Account4', label: '“How do I add a new payment method?”', trigger: 'Account4' },
        { value: 'talking', label: 'Start talking again', trigger: '1' },
        { value: 'Contact', label: 'Contact us for more details...', trigger: 'Contac' }
  
      ],
    },
    {
      id: 'Account1',
      message: 'You can update your personal and payment information in the app under \'Account Settings\'.',
      trigger :'AccountEnd',
    },
    {
      id: 'Account2',
      message: 'You will receive notifications about booking confirmations, upcoming reservations, and promotional offers.',
      trigger : 'AccountEnd',
    },
    {
      id: 'Account3',
      message: 'You can opt out via the \'Unsubscribe\' link in any marketing email or adjust your preferences in the app settings.',
      trigger : 'AccountEnd',
    },
    {
      id: 'Account4',
      message: 'New payment methods can be added under the \'Payment Options\' section in your account settings.',
      trigger : 'AccountEnd',
    }, 
    {
      id: 'AccountEnd',
      options: [
        { value: 'Account', label: 'More questions...', trigger: 'Account' },
        { value: 'talking', label: 'Start talking again', trigger: '1' },
        { value: 'Contac', label: 'Please contact us for detailed information.', trigger: 'Contac' }
  
      ],
    },
   //Account end
   

  //yönllendirme 
  {
    id:'Contac',
    message: 'If you need live support,contact us from info@sumtech.io or you can cal the Toll Free Customer Care: +(90) 539 616 27 34',
    trigger:'done',
  },
  {
      id: 'done',
      message: 'Would you like to continue?',
      trigger: 'continue',
  },
  {
      id: 'continue',
      options: [
        { value: 'talking', label: 'Keep Talking', trigger: '2' },
        { value: 'finish', label: 'Finish It', trigger: 'finish' },    
      ],      
  },
  {
      id: 'finish',
      message: 'Conversation ended. Have a good day.',
      trigger:'2',
  },
];

export default Step;
