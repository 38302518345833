import React from 'react';
import { useTranslation } from 'react-i18next';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import ContactOne from "./ContactOne";

const Contact = () => {
    const { t } = useTranslation(); // i18n translation hook

    return (
        <>
            <SEO title={t("contact_page_title")} />
            <Layout>
                <BreadcrumbOne 
                    title={t("breadcrumb_title")}
                    rootUrl="/"
                    parentUrl={t("breadcrumb_parent_url")}
                    currentUrl={t("breadcrumb_current_url")}
                />
                <div className="main-content">
                    {/* İletişim Alanı Başlangıç  */}
                    <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = {t("contact_form_subtitle")}
                                        title = {t("contact_form_title")}
                                        description = ""
                                    />
                                </div>
                            </div>
                            <ContactOne />
                        </div>
                    </div>
                    {/* İletişim Alanı Bitiş */}
                </div>
            </Layout>
        </>
    )
}
export default Contact;
