import React, { useEffect } from 'react';

const Darkmode = () => {
    useEffect(() => {
        // Yerel depolamadan temayı al
        const savedTheme = localStorage.getItem('theme');
        
        // Eğer bir tema kaydedilmemişse veya kaydedilen tema 'light' ise, varsayılan olarak 'light' temasını ayarla
        if (!savedTheme) {
            document.querySelector('body').classList.add('light');
            localStorage.setItem('theme', 'light'); // Varsayılan olarak 'light' temasını kaydet
        } else if (savedTheme === 'light') {
            document.querySelector('body').classList.add('light');
        } else {
            document.querySelector('body').classList.remove('light');
        }
    }, []);

    const switchTheme = () => {
        const body = document.querySelector('body');
        body.classList.toggle('light');
        localStorage.setItem('theme', body.classList.contains('light') ? 'light' : 'dark');
    }

    return (
        <button id="darkmode" onClick={switchTheme}>
            <img className="light-icon" src={`${process.env.PUBLIC_URL}/images/icons/sun-01.svg`} alt="Sun images" />
            <img className="dark-icon" src={`${process.env.PUBLIC_URL}/images/icons/vector.svg`} alt="Sun images" />
        </button>
    );
}

export default Darkmode;
