import styled from 'styled-components';
import defaultTheme from '../../theme';

const ChatBotContainer = styled.div`
  background: #F8F5F0;
  border-radius: 30px;
  box-shadow: -10px 10px 8px -3px rgba(33,33,33,1),
  -20px 20px 8px 0px rgba(33,33,33,0.7),
  -30px 30px 9px 0px rgba(33,33,33,0.4),
  -40px 40px 8px 0px rgba(33,33,33,0.1);
  font-family:Jost;
  overflow: hidden;
  position: ${({ floating }) => (floating ? 'fixed' : 'relative')};
  bottom: ${({ floating, floatingStyle }) =>
    floating ? floatingStyle.bottom || '45px' : 'initial'};
  top: ${({ floating, floatingStyle }) => (floating ? floatingStyle.top || 'initial' : 'initial')};
  right: ${({ floating, floatingStyle }) => (floating ? floatingStyle.right || '78px' : 'initial')};
  left: ${({ floating, floatingStyle }) =>floating ? floatingStyle.left || 'initial' : 'initial'};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  z-index: 1000;
  transform: ${({ opened }) => (opened ? 'scale(1)' : 'scale(0)')};
  transform-origin: ${({ floatingStyle }) => floatingStyle.transformOrigin || 'bottom right'};
  transition: transform 0.4s ease;

  @media screen and (max-width: 568px) {
    border-radius: ${({ floating }) => (floating ? '0' : '')};
    bottom: 0 !important;
    left: initial !important;
    height: 100%;
    right: 0 !important;
    top: initial !important;
    width: 100%;
  }
  
`;


export default ChatBotContainer;
