import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'

import Home from "./pages/Home";
import About from "./pages/About";
import For_Property_Owner from "./pages/ForPropertyOwner";
import Privacypolicy from "./pages/privacy-policy";
import Contact from "./elements/contact/Contact";
import Error from "./pages/Error";
// Import Css Here 
import './assets/scss/style.scss';


const App = () => {
    return (
        <Router>
            <PageScrollTop>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={Home}/>
                    <Route path={`${process.env.PUBLIC_URL + "/about"}`} exact component={About}/>
                    <Route path={`${process.env.PUBLIC_URL + "/propertyowner"}`} exact component={For_Property_Owner}/>
                    <Route path={`${process.env.PUBLIC_URL + "/contact"}`} exact component={Contact}/>
                    <Route path={`${process.env.PUBLIC_URL + "/error"}`} exact component={Error}/>
                    <Route path={`${process.env.PUBLIC_URL + "/privacy-policy"}`} exact component={Privacypolicy}/>
              </Switch>
            </PageScrollTop>
        </Router>
    )
}


export default App
