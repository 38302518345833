import React from 'react';
import Typed from 'react-typed';
import { useTranslation } from "react-i18next";
const PrivacyPage = () => {
    const { t } = useTranslation();
return (
    <div className="rwt-about-area rn-section-gap">
        <div className="container">
            <div className="row row--30 align-items-center">
                <div className="col-lg-12 mt_md--40 mt_sm--40 p-50">
                    <div className="content">
                        <div className="section-title">
                        <h2 className="title " style={{textAlign:"center"}}> {" "}
                            <Typed className="theme-gradient"
                                strings={[
                                    "PRIVACY AND SECURITY POLICY",                                    
                                ]}
                                typeSpeed={80}
                                backSpeed={5}
                                backDelay={1000}
                                loop
                            />
                        </h2>
                        <p>
                        <p>{t('PrivacyPolicyDesc')} {t('PrivacyPolicyDesc1')} </p> 
                        </p>
                        <h6>{t('CreditCardSecurityTitle')}</h6>
                        <p>{t('CreditCardSecurityDesc')} {t('CreditCardSecurityDesc1')}</p>
                        <h6>{t('MailOrderSecurityTitle')}</h6>
                        <p>{t('MailOrderSecurityDesc')}</p>
                        <h6>{t('ThirdPartyWebsitesTitle')}</h6>
                        <p>{t('ThirdPartyWebsitesDesc')}</p>
                        <h6>{t('ExceptionalCasesTitle')}</h6>
                        <p>{t('ExceptionalCasesDesc')}
                        <ul>
                            <li><span>01-</span>{t('ExceptionalCasesDesc1')}</li>
                            <li><span>02-</span>{t('ExceptionalCasesDesc2')}</li>
                            <li><span>03-</span>{t('ExceptionalCasesDesc3')}</li>
                            <li><span>04-</span>{t('ExceptionalCasesDesc4')}</li>
                         </ul>
                        </p>
                        <h6>{t('EmailSecurity')}</h6>
                        <p>{t('EmailSecurityDesc')}</p>
                        <h6>{t('BrowserCookies')}</h6>
                        <p>{t('BrowserCookiesDesc')}</p>
                        <p>{t('CompanyDetails')}
                        <br /><br/>{t('CompanyName')} <br />
                        Address: Beşiktaş/Istanbul <br /> 
                        Email:  info@sumtech.io <br />
                        Tel: (+90) 539 616 2734  <br />                       
                        </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>   
    </div>
 )
}

export default PrivacyPage
