import styled from 'styled-components';
import defaultTheme from '../../theme';

const HeaderTitle = styled.h2`
  margin: 0;
  font-size:18px;
  color :#000000;
`;

HeaderTitle.defaultProps = {
  theme: defaultTheme
};

export default HeaderTitle;
