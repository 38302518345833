import React from 'react';
import Typed from 'react-typed';
import { useTranslation } from 'react-i18next';

const AboutOne = () => {
    const { t } = useTranslation();
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">

                    <div className="col-lg-6 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title" style={{ textAlign: "center" }}>{" "}
                                    <Typed className="theme-gradient"
                                        strings={[
                                            t('Istanbul'),
                                            t('SmartApt'),
                                            t('Ortakoy'),
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h2>
                                <p>{t('SmartaptLocation')}</p>
                                <p>{t('WalkingDistance')}</p>
                                <p>{t('FinancialDistrict')}</p>
                                <p>{t('Nightclubs')}</p>
                                <p>{t('ApartmentDescription')}</p>
                                <p>{t('UnitFeatures')}</p>
                                <p>{t('WiFiAndTV')}</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/about1.png" alt={t('Istanbul')} />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AboutOne;
