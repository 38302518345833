import styled from 'styled-components';

const FloatButton = styled.a`
  align-items: center;
  cursor: pointer;
  background:#DDDDDD;
  bottom: 18px;
  border-radius: 100%;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  fill: #E7E7E6;
  height: 70px;
  justify-content: center;
  position: fixed;
  right: 70px;
  transform: ${props => (props.opened ? 'scale(0)' : 'scale(1)')};
  transition: transform 0.3s ease;
  width: 70px;
  z-index: 999;
  &:hover {
    background:#AFCFE1;
    box-shadow: 0px 0px 50px 0px rgba(180,143,110,1),
    0px 0px 31px -142px rgba(163,139,115,1);
  }
`;


FloatButton.defaultProps = {
  theme: {
    headerBgColor: '#FFE61E',
    headerFontColor: '#222222'
  }
};

export default FloatButton;
