import React from 'react';
import { useTranslation } from 'react-i18next';
import ScrollAnimation from "react-animate-on-scroll";

const ServiceThree = ({ textAlign, serviceStyle }) => {
    const { t } = useTranslation();

    const ServiceList = [
        {
            image: '/images/service/hospitalty.png',
            title: t('ExceptionalGuestExperience'),
            description: t('ExceptionalGuestExperienceDesc')
        },
        {
            image: '/images/service/operations.png',
            title: t('EfficientOperations'),
            description: t('EfficientOperationsDesc')
        },
        {
            image: '/images/service/support.png',
            title: t('ComprehensiveSupport'),
            description: t('ComprehensiveSupportDesc')
        }
    ];

    return (
        <div className="row row--15 service-wrapper">
            {ServiceList.map((val, i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="image">
                                    <img src={`${val.image}`} alt="card Images" />
                                </div>
                                <div className="content">
                                    <h4 className="title"><a dangerouslySetInnerHTML={{ __html: val.title }}></a></h4>
                                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: val.description }}></p>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    );
};

export default ServiceThree;
