import React from 'react';
import HeaderOne from "./header/HeaderOne";
import HeaderTopNews from "./header/HeaderTopNews";
import FooterTwo from "./footer/FooterTwo";
import Copyright from "./footer/Copyright";
import Chat from './chat_query';

const Layout = ({ children, showFooter = true }) => {
    return (
        <>
            <Chat/>
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />
                {children}
                {showFooter && <FooterTwo />}
                {showFooter && <Copyright />}
            </main>
        </>
    );
}
export default Layout;