import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceOne = ({textAlign, serviceStyle}) => {
    const { t } = useTranslation();

    const ServiceList = [
        {
            image:"/images/service/revenue.png",
            title: t('BetterControl'),
            description: t('BetterControlDesc')
        },
        {
            image:"/images/service/cost.png",
            title: t('StayAhead'),
            description: t('StayAheadDesc')
        },
        {
            image: "/images/service/business.png",
            title: t('BusinessPartner'),
            description: t('BusinessPartnerDesc')
        }
    ];

    return (
        <div className="row row--15 service-wrapper">
            {ServiceList.map((val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                <img src={`${val.image}`} alt="card Images" />
                            </div>
                            <div className="content">
                                <h4 className="title w-600">
                                    <Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link>
                                </h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    );
}

export default ServiceOne;
