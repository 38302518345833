import React from 'react';
import { FiArrowRight } from "react-icons/fi";
import { useTranslation } from 'react-i18next';

const CalltoActionTwo = () => {
    const { t } = useTranslation();

    return (
        <div className="rn-callto-action clltoaction-style-default style-2 bg_image bg_image_fixed" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/calltoaction.jpg)` }} data-black-overlay="8">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="inner">
                            <div className="content text-center">
                                <h2 className="title mb--0">{t('CallToActionTitle1')}</h2>
                                <div className="call-to-btn text-center">
                                    <a className="btn-default btn-icon" href="/contact">{t('ContactUs1')} <i className="icon"><FiArrowRight /></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CalltoActionTwo;
