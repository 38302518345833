import React from 'react';
import Logo from "../../elements/logo/Logo";
import Nav from './Nav';
import { FiX } from "react-icons/fi";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import appStore from "../../store/appStore";

const MobileMenu = ({show, onClose}) => {
    const {t, i18n } = useTranslation();
    const [language, setLanguage] = useState("");
    const changeLanguageHandler = (languageValue) => {
        console.log(languageValue);
        localStorage.setItem("language", JSON.stringify(languageValue)); 
        appStore.language = languageValue;
        setLanguage(languageValue);
        i18n.changeLanguage(languageValue); 
        
    };
    useEffect(() => {
        const savedLang = localStorage.getItem("language");
        if (savedLang) {
            const lang = JSON.parse(savedLang);
            appStore.language = lang;
            setLanguage(lang);
            i18n.changeLanguage(lang);  
        } else {
            i18n.changeLanguage('en');
        }
    }, [i18n]);
    var elements = document.querySelectorAll('.popup-mobile-menu .has-droupdown > a');
    var elementsTwo = document.querySelectorAll('.popup-mobile-menu .with-megamenu > a');
    for(var i in elements) {
        if(elements.hasOwnProperty(i)) {
            elements[i].onclick = function() {
                this.parentElement.querySelector('.submenu').classList.toggle("active");
                this.classList.toggle("open");
            }
        }
    }

    for(var i in elementsTwo) {
        if(elementsTwo.hasOwnProperty(i)) {
            elementsTwo[i].onclick = function() {
                this.parentElement.querySelector('.rn-megamenu').classList.toggle("active");
                this.classList.toggle("open");
            }
        }
    }
    return (
        <div className={`popup-mobile-menu ${show ? "active": ""}`}>
            <div className="inner">
                <div className="header-top">
                    <Logo 
                        image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                        image2={`${process.env.PUBLIC_URL}/images/logo/logo_dark.png`}
                    />
                    <div className="close-menu">
                        <span className="close-button" onClick={onClose}><FiX /></span>
                    </div>
                </div>
                
                <Nav />
           
                             
                <div className="language-selector">
                                    <a 
                                        className={`lang-button ${appStore.language === 'en' ? 'active' : ''}`} 
                                        onClick={() => changeLanguageHandler('en')}
                                    >
                                        EN
                                    </a>
                                    <a 
                                        className={`lang-button ${appStore.language === 'tr' ? 'active' : ''}`} 
                                        onClick={() => changeLanguageHandler('tr')}
                                    >
                                        TR
                                    </a>
                                </div>
            </div>
        </div>
    )
}
export default MobileMenu;