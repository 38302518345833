import styled from 'styled-components';

const Content = styled.div`
height: calc(${props => props.height} - ${props => (props.hideInput ? '56px' : '150px')});
overflow-y: scroll;
margin-top: 3px;
margin-bottom:3px;
padding-top: 6px;
padding-bottom: 6px;


@media screen and (max-width: 568px) {
  height: ${props => (props.floating ? 'calc(100% - 150px)' : '')};
}
`;



export default Content;
