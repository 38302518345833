import React from 'react';
import { useTranslation } from 'react-i18next';

const AboutOne = () => {
    const { t } = useTranslation();

    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/about.png" alt={t('AboutCompany')} />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">{t('AboutCompany')}</h2>
                                <p>{t('HospitalityFuture')}</p>
                                <p>{t('HospitalityPlatform')}</p>
                            </div>
                            <div className="view-more-button mt--35">
                                    <a className="btn-default" href="https://smart.specialhospitality.com/">{t('VisitSummit')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutOne;
