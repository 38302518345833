import React from 'react';
import { Link } from "react-router-dom";
import CalltoActionSeven from "../../elements/calltoaction/CalltoActionSeven";
import footerOne from "../../data/footer/footerOne.json";
import ScrollTop from "./ScrollTop";
import { FiFacebook, FiTwitter, FiInstagram } from "react-icons/fi";
import { useTranslation } from 'react-i18next';

const footerIntem = footerOne[0];
const footerIntemOne = footerOne[1];
const footerIntemTwo = footerOne[2];
const footerIntemThree = footerOne[3];
const footerIntemFour = footerOne[4];
const footerIntemFive = footerOne[5];

const indexOneLink = (footerIntemOne.quicklink);
const indexTwoLink = (footerIntemTwo.quicklink);
const indexThreeLink = (footerIntemThree.quicklink);

const FooterTwo = () => {
    const { t } = useTranslation();

    return (
        <>
            <footer className="rn-footer footer-style-default variation-two">
                <CalltoActionSeven />
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <h4 className="title">{t('StayWithUs')}</h4>
                                    <div className="inner">
                                        <h6 className="subtitle">{t('ReachUsOnSocial')}</h6>
                                        <ul className="social-icon social-default justify-content-start">
                                            <li><a href="https://www.facebook.com/summitvista" target="_blank" rel="noopener noreferrer"><FiFacebook /></a></li>
                                            <li><a href="https://x.com/SummitVistaApp" target="_blank" rel="noopener noreferrer"><FiTwitter /></a></li>
                                            <li><a href="https://www.instagram.com/summitvista.io/" target="_blank" rel="noopener noreferrer"><FiInstagram /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 text-right"> {/* App Store butonunu sağa hizala */}
                                <div className="rn-footer-widget">
                                <h4 className="title" style={{ fontSize:20}}>{t('appStore')}</h4>
                                    <a href="https://apps.apple.com/tr/app/summit-vista/id6496853906?l=tr" target="_blank" rel="noopener noreferrer">
                                        <img src="/images/appstore.png" alt="App Store" style={{ width: '189px'}} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <ScrollTop />
        </>
    )
}

export default FooterTwo;
