import React, { Component } from 'react';

class GoogleMapIframe extends Component {
  render() {
    return (
      <div style={{ height: '400px', width: '68%' }}>
        <iframe
          width="100%"
          height="100%"
          style={{ border: 0 }}
          loading="lazy"
          allowFullScreen
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3008.7247814665707!2d29.028486300000008!3d41.0531473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab7cc9deb96b5%3A0x9e109bd3a6cbce9a!2s%C4%B0stanbul%20Smart%20Apt%20Portakal!5e0!3m2!1str!2str!4v1725485657824!5m2!1str!2str"
        ></iframe>
      </div>
    );
  }
}

export default GoogleMapIframe;
