import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import tr from './tr';
import en from './en';

let resources = {
    en: {
        translation: en
    },
    tr: {
        translation: tr
    },
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en', 
        detection: {
            order: ['localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            caches: ['localStorage'], 
        },
        interpolation: {
            escapeValue: false, 
        },
    });

export default i18n;
