import React from 'react';

const CloseIcon = () => (
  <img 
    src="../images/Close.png" 
    height="30" 
    width="30" 
    style={{ 
      position: 'fixed',
      top: '13px', // İstediğiniz mesafeye göre ayarlayın
      right: '13px' // İstediğiniz mesafeye göre ayarlayın
    }} 
  />
);

export default CloseIcon;
