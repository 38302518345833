import React, { Component } from 'react';

class GoogleMapIframe extends Component {
  render() {
    return (
      <div style={{ height: '550px', width: '100%' }}>
        <iframe
          width="100%"
          height="100%"
          style={{ border: 0 }}
          loading="lazy"
          allowFullScreen
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1504.3571930857527!2d29.0286917!3d41.0533746!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab75970791445%3A0x999c0eabdc182413!2sSummit%20Up%20Life!5e0!3m2!1str!2str!4v1719909009678!5m2!1str!2str"
        ></iframe>
      </div>
    );
  }
}

export default GoogleMapIframe;
